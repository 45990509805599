/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TourMap from './TourMap'
import '../css/WorldMap.css'

const END_POINT = '/data/intlMapData.json'
const END_POINT2 = '/data/domMapData.json'
class WorldMap extends Component {
  constructor(props) {
    super(props)
    this.path = props.history.location.pathname // 現在のパス
    this.state = {
      worldData: [],
      domData: [],
      value: 0,
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ worldData: result.data })
    })
    axios.get(END_POINT2).then(result => {
      this.setState({ domData: result.data })
    })
  }

  handleChange(index) {
    this.setState({ value: index })
  }

  render() {
    const {
      state: { worldData, domData, value },
      props: { history },
    } = this
    if (worldData.length === 0) return ''
    if (domData.length === 0) return ''
    return (
      <div>
        <Button onClick={() => history.goBack()} className="backButton">
          <ArrowBackIosIcon />
          BACK
        </Button>
        <h1>WORLD MAP</h1>
        <div>
          <AppBar position="static">
            <Tabs value={value}>
              <Tab label="INTERNATIONAL" onClick={() => this.handleChange(0)} />
              <Tab label="DOMESTIC" onClick={() => this.handleChange(1)} />
              {/* <Tab label="Item Three" onClick={() => this.handleChange(2)} /> */}
            </Tabs>
          </AppBar>
          <div value={value} index={0} className={this.state.value !== 0 ? 'hidden' : ''}>
            <TourMap data={worldData.map} />
          </div>
          <div value={value} index={1} className={this.state.value !== 1 ? 'hidden' : 'domMap'}>
            <TourMap data={domData.map} />
          </div>
          {/* <div value={value} index={2} className={this.state.value !== 2 ? 'hidden' : ''}>
            Item Three
          </div> */}
        </div>
      </div>
    )
  }
}
export default withRouter(WorldMap)
WorldMap.propTypes = {
  history: PropTypes.object.isRequired,
}
