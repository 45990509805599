import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import '../css/Home.css'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import LinkIcon from '@material-ui/icons/Link'
import RoomIcon from '@material-ui/icons/Room'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import MkIcon from '../icons/MkIcon'
import Topics from './Topics'
import GLOBAL from '../Global'
import MyButton from '../modules/MyButton'
import mainVisual from '../images/main-visual.jpg'
import midkniteathletics from '../images/midkniteathletics.png'
import midknitetreks from '../images/midknitetreks.png'
import project1 from '../images/project1.png'
import project2 from '../images/project2.png'
import mkj from '../images/mkj.jpg'
import check from '../images/img-check.jpg'
import alltimeschedule from '../images/alltimeschedule.png'
import worldmap from '../images/worldmap.png'
import HomeContentBox from '../modules/HomeContentBox'

class Home extends Component {
  componentDidMount() {
    document.title = GLOBAL.SITENAME
  }

  render() {
    return (
      <main className="home">
        <h1 className="mainVisual">
          <img src={mainVisual} alt={GLOBAL.SITENAME} />
        </h1>
        <h2 className="heading02">
          <MkIcon />
          {GLOBAL.HOME.TOPICSTITLE}
        </h2>
        <div className="homeTopics">
          <Topics max="4" />
          <MyButton link="/topics" text="more" />
        </div>
        <div className="homeContentBox">
          <HomeContentBox
            icon={<NewReleasesIcon />}
            title="PROJECT"
            src={midkniteathletics}
          />
          <HomeContentBox src={midknitetreks} />
          <HomeContentBox src={project1} />
          <HomeContentBox src={project2} />
          <HomeContentBox
            icon={<LinkIcon />}
            title={GLOBAL.HOME.CONTENT_TITLE3}
            link="https://midknitejapan.com"
            src={mkj}
            target="blank"
          />
          <HomeContentBox src={check} />
          <HomeContentBox
            icon={<EmojiEmotionsIcon />}
            title={GLOBAL.HOME.CONTENT_TITLE4}
            link="/alltimeschedule"
            src={alltimeschedule}
          />
          <HomeContentBox
            icon={<RoomIcon />}
            title="WORLD MAP"
            link="/worldmap"
            src={worldmap}
          />
        </div>
      </main>
    )
  }
}

export default withRouter(Home)
