import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import GridOnIcon from '@material-ui/icons/GridOn'
import CircularProgress from '@material-ui/core/CircularProgress'

let keepState = false
let keepPage = ''

class TopicsList extends Component {
  constructor() {
    super()
    this.state = {
      toggle: keepState || false,
      text: 'change style to list',
      page: keepPage !== '' ? keepPage : 1,
    }
  }

  componentDidMount() {
    if (keepState) {
      this.setState({
        toggle: true,
      })
    }
    if (keepPage) {
      this.setState({
        page: keepPage,
      })
    }
  }

  toggleStyle() {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      this.setState({
        text: 'change style to list',
      })
      keepState = false
    } else {
      this.setState({
        text: 'change style to table',
      })
      keepState = true
    }
  }

  handlePage(e, value) {
    this.setState({ page: value })
    window.scrollTo(0, 0)
    keepPage = value
  }

  render() {
    const { data, max } = this.props
    const { toggle, text, page } = this.state
    // let reverseData = data.concat()
    let reverseData = _.orderBy(data, ['generalId'], ['desc'])

    const dataLength = reverseData.length
    const perPage = 20
    let pageNumber = 1
    if (dataLength > perPage) {
      pageNumber = parseInt(dataLength / perPage, 10) + 1
      reverseData = reverseData.slice(perPage * (page - 1), perPage * page)
    }
    if (dataLength === 0)
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )

    return (
      <>
        {!max ? (
          <Button
            size="small"
            className="toggleButton"
            // variant="contained"
            onClick={() => {
              this.toggleStyle(this)
            }}
          >
            {toggle ? <GridOnIcon /> : <ListIcon />}
            {text}
          </Button>
        ) : (
          ''
        )}

        <ul className={`list archive${toggle ? ' listStyle' : ''}`}>
          {reverseData.map((e, i) => {
            const link = `/topics/${e.slug}`
            const tag = e.tag.toUpperCase()
            let image
            if (e.image !== '') {
              image = e.imageUrl
            } else {
              image = 'img-default.png'
            }
            if (max) {
              if (i < max) {
                return (
                  <li key={e.slug}>
                    <span className="date">
                      {e.date} <span className={`tag ${e.tag}`}>{tag}</span>
                    </span>
                    <Link to={link}>
                      <div className="thumbnail">
                        <img src={image} alt={e.title} />
                      </div>
                      {e.title}
                    </Link>
                  </li>
                )
              }
              return null
            }
            return (
              <li key={e.slug}>
                <span className="date">
                  {e.date} <span className={`tag ${e.tag}`}>{tag}</span>
                </span>
                <Link to={link}>
                  <div className="thumbnail">
                    <img src={image} alt={e.title} />
                  </div>
                  {e.title}
                </Link>
              </li>
            )
          })}
        </ul>
        {!max && (
          <Pagination
            count={pageNumber}
            className="pagination"
            page={page}
            onChange={(e, value) => {
              this.handlePage(e, value)
            }}
          />
        )}
      </>
    )
  }
}

export default withRouter(TopicsList)

TopicsList.propTypes = {
  data: PropTypes.array,
  max: PropTypes.string,
}

TopicsList.defaultProps = {
  data: null,
  max: null,
}
