/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import UpdateIcon from '@material-ui/icons/Update'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CircularProgress from '@material-ui/core/CircularProgress'
import GLOBAL from '../Global'

class TopicsSingle extends Component {
  setTitle = title => {
    document.title = `${title} | TOPICS | ${GLOBAL.SITENAME}`
  }

  render() {
    const {
      props: { category, data, history },
    } = this
    if (!data)
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    this.setTitle(data.title)

    let image
    if (data.image !== '') {
      image = data.imageUrl
    } else {
      image = 'img-default.png'
    }

    return (
      <div className={`single ${category}`}>
        <Button onClick={() => history.goBack()} className="backButton">
          <ArrowBackIosIcon />
          BACK
        </Button>
        <h1>{data.title}</h1>
        <div className="dateCreated">
          <UpdateIcon /> {data.date} UPDATED
        </div>
        <div className="tagContainer">
          <div className={`tag ${data.tag}`}>{data.tag.toUpperCase()}</div>
        </div>
        {data.image && (
          <div className="mainVisual">
            <img src={image} alt={data.title} />
          </div>
        )}
        <div className="text" dangerouslySetInnerHTML={{ __html: data.text }} />
      </div>
    )
  }
}

export default withRouter(TopicsSingle)

TopicsSingle.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
  history: PropTypes.object.isRequired,
}

TopicsSingle.defaultProps = {
  data: null,
  category: null,
}
