import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
export default function SlickSlider(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const { images, maxWidth, captions } = props
  return (
    <Slider
      {...settings}
      style={{
        width: '100%',
        maxWidth: `${maxWidth}px`,
        margin: '0 auto',
      }}
    >
      {images.length !== 0 &&
        images.map((e, i) => {
          return (
            <div key={e}>
              <img src={e} alt="" />
              <p>{captions[i]}</p>
            </div>
          )
        })}
    </Slider>
  )
}
SlickSlider.propTypes = {
  images: PropTypes.array.isRequired,
  maxWidth: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  captions: PropTypes.array.isRequired,
}
