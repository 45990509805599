import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function HmkIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <g id="layer101" stroke="none">
          <path
            d="M6.1,15.7c0-0.9,0-1.8,0-2.8c-0.2,0-0.4,0-0.5,0L5.5,13c-0.5,0.8-1,1.7-1.5,2.5c-0.1,0.1-0.1,0.2-0.2,0.2
    c-1.1,0-2.2,0-3.3,0c0,0-0.1,0-0.2,0c0.1-0.1,0.2-0.3,0.2-0.4c0.6-0.9,1.2-1.9,1.9-2.8c0.1-0.1,0.1-0.2,0-0.3
    C1.6,11,0.9,9.8,0.2,8.6c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0,0.2,0c1.2,0,2.4,0,3.5,0c0.2,0,0.2,0,0.3,0.2c0.4,0.7,0.8,1.5,1.2,2.2
    c0.1,0.1,0.1,0.2,0.3,0.2c0.2,0,0.4,0,0.6,0c0-0.9,0-1.7,0-2.6c0.1,0,0.2,0,0.2,0c1.5,0,3,0,4.5,0c0.2,0,0.3,0,0.3,0.2
    c0.2,0.8,0.5,1.7,0.7,2.5c0.1,0.2,0.1,0.4,0.2,0.6c0-0.1,0.1-0.1,0.1-0.1c0.3-1,0.5-2,0.8-3c0-0.2,0.1-0.2,0.3-0.2
    c1.7,0,3.4,0,5.1,0c0.2,0,0.2,0,0.2,0.2c0,0.8,0,1.5,0,2.3c0,0.1,0,0.1,0.1,0.2c0-0.1,0.1-0.1,0.1-0.2c0.5-0.8,1-1.6,1.4-2.4
    c0.1-0.1,0.1-0.2,0.2-0.2c1.1,0,2.2,0,3.3,0h0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.6,0.9-1.2,1.9-1.9,2.8c-0.1,0.1-0.1,0.2,0,0.4
    c0.7,1.2,1.4,2.4,2.1,3.6c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.2,0c-1.2,0-2.4,0-3.6,0c-0.1,0-0.2-0.1-0.3-0.2
    c-0.4-0.9-0.9-1.8-1.3-2.6c0-0.1-0.1-0.1-0.1-0.2c0,0.1,0,0.1,0,0.2c0,0.8,0,1.7,0,2.5c0,0.2,0,0.2-0.2,0.2c-1.2,0-2.3,0-3.5,0
    c-0.2,0-0.2-0.1-0.2-0.2c0-1.4,0-2.8,0-4.2c0-0.1,0-0.1,0-0.2l0,0c-0.1,0.2-0.1,0.5-0.2,0.7c-0.3,1.3-0.7,2.5-1,3.8
    c0,0.1-0.1,0.2-0.2,0.2c-0.7,0-1.4,0-2.1,0c-0.1,0-0.2,0-0.2-0.2c-0.3-1-0.6-2.1-0.9-3.1c-0.1-0.4-0.2-0.8-0.4-1.2
    c0,0.1,0,0.2,0,0.2c0,1.4,0,2.7,0,4.1c0,0.1,0,0.2-0.2,0.2c-1,0-2.1,0-3.1,0C6.2,15.7,6.2,15.7,6.1,15.7z"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
