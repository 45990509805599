import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import MapStyle from './MapStyle'
import Marker from './Marker'

class TourMap extends Component {
  fitBounds = (map, maps, data) => {
    const bounds = new maps.LatLngBounds()

    data.markerData.forEach(e => {
      bounds.extend(new maps.LatLng(e.lat, e.lng))
    })
    map.fitBounds(bounds, 100)
  }

  renderPolylines = (map, maps, data) => {
    if (!data.createLine) return
    data.createLine.forEach(e => {
      const geodesicPolyline = new maps.Polyline({
        path: e,
        geodesic: false, // true or false
        strokeColor: 'lightblue',
        strokeOpacity: 0.7,
        strokeWeight: 3,
      })
      geodesicPolyline.setMap(map, data)
    })
    this.fitBounds(map, maps, data)
  }

  render() {
    const {
      props: { data },
    } = this
    return (
      <div className="tourMap">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyA8MsmlyNqswURR6wxRwZaZiWF5qZT4tA0',
            language: 'en',
          }}
          defaultCenter={data.center}
          defaultZoom={data.zoom}
          yesIWantToUseGoogleMapApiInternals
          options={{
            disableDefaultUI: true, // disable default map UI
            draggable: true, // make map draggable
            keyboardShortcuts: false, // disable keyboard shortcuts
            scaleControl: true, // allow scale controle
            scrollwheel: true, // allow scroll wheel
            styles: MapStyle, // change default map styles
          }}
          onGoogleApiLoaded={({ map, maps }) =>
            this.renderPolylines(map, maps, data)
          }
        >
          {data.markerData.map(e => (
            <Marker
              key={e.popupTitle}
              title={e.popupTitle}
              content={e.popupContent}
              lat={e.lat}
              lng={e.lng}
            />
          ))}
        </GoogleMapReact>
      </div>
    )
  }
}

export default TourMap

TourMap.propTypes = {
  data: PropTypes.object.isRequired,
}
