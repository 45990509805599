/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'
import mk from '../images/mk.png'

const About = () => {
  return (
    <>
      <h1>{GLOBAL.ABOUT.TITLE}</h1>
      {/* <div dangerouslySetInnerHTML={{ __html: GLOBAL.ABOUT.TEXT }} /> */}
      <div>
        <p style={{ margin: '0 auto', maxWidth: '400px' }}>
          <img src={mk} alt="" />
        </p>
        <p>MICHIHIRO KOSEKI</p>
        <p>
          2004年9月、「€-MK WORLD TOUR 2004
          euro-」で単独ワールドツアーデビュー。以後16年に渡り、全世界への旅を続けてきた。2019年に開催した15周年記念ツアー「MK
          15TH ANNIVERSARY WORLD TOUR 2019
          €xplorer」をもって総訪問国数70ヵ国を達成した。
        </p>
      </div>
    </>
  )
}

export default withRouter(About)
