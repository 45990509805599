import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function JhtBoldIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <g id="layer101" stroke="none">
          <path
            d="M23.9,8.4V6.6H0.1v1.9h4.2v5.1c0,1.4-0.2,1.6-1.1,1.6c-0.3,0-0.9-0.1-1-0.5C2,14.3,2,14.2,2,12.9v-0.5h-2v0.5
            c0,1.4,0,2.3,0.5,3.1c0.5,0.7,1.4,1.1,2.5,1.1c3.1,0,3.1-2.5,3.1-3.4V8.4h2.1V17h1.9v-3.7h3.5V17h1.9V8.4h2.1v5.2
            c0,0.9,0,3.4,3.1,3.4c1.2,0,2.1-0.4,2.5-1.1c0.5-0.8,0.5-1.7,0.5-3.1v-0.5h-2v0.5c0,1.3,0,1.4-0.1,1.6c-0.1,0.4-0.7,0.5-1,0.5
            c-0.9,0-1.1-0.1-1.1-1.6V8.4H23.9z M10.2,11.3V8.2h3.5v3.1H10.2z"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
