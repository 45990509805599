import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import TourList from './TourList'
import Single from '../components/Single'
import GLOBAL from '../Global'
import '../css/ScheduleTour.css'

const END_POINT = 'https://mk-official-data.firebaseio.com/tour.json'
// const END_POINT = '/data/tour.json'

class Tour extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tour: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ tour: result.data })
    })
  }

  render() {
    const {
      state: { tour },
      props: { history },
    } = this
    const path = history.location.pathname // 現在のパス
    if (path === '/tour/' || path === '/tour') {
      return (
        <div>
          <h1>{GLOBAL.TOUR.TITLE}</h1>
          <TourList data={tour} />
        </div>
      )
    }
    return (
      <div>
        <Single category="tour" data={tour} path={path} />
      </div>
    )
  }
}

export default withRouter(Tour)

Tour.propTypes = {
  history: PropTypes.object,
}

Tour.defaultProps = {
  history: null,
}
