import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function MimIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M20.6,11.9c-1.2-1.2-2.5-2.1-3.8-2.8c2.3-1.3,2.4-4.1,2.4-4.2l-0.8,0c0,0-0.2,2.9-2.5,3.8l0,0.1c-0.7-0.3-1.3-0.5-2-0.6
          c1.6-1.9,0.9-4.5,0.9-4.7L14,3.6c0,0,0.7,2.7-1.1,4.3c-0.5,0-0.9-0.1-1.4-0.1c-0.3,0-0.6,0-0.9,0.1c0.3-2.7-2-4.5-2.1-4.5L8.1,4
          c0,0,2.2,1.7,1.8,4c-0.7,0.2-1.4,0.4-2,0.6c-1.1-2.6-4.1-2.9-4.2-3L3.6,6.5c0,0,2.7,0.3,3.6,2.5c-2.4,1.2-3.8,2.9-3.9,3l-0.5,0.7
          h0.7c2.7,2.7,5.6,4,8.6,4c0.1,0,0.3,0,0.4,0c4.9-0.2,8.2-4,8.4-4.2l0.6-0.7H20.6z M11.1,8.7c-1.7,0.4-2.9,1.9-2.9,3.6
          c0,1.6,1,3,2.4,3.5c-2.2-0.3-4.4-1.5-6.6-3.5C4.9,11.4,7.6,9,11.1,8.7z M13.3,15.8c1.4-0.5,2.5-1.9,2.5-3.5c0-1.8-1.2-3.2-2.9-3.6
          c2.4,0.2,4.7,1.4,7,3.6C19.1,13.1,16.6,15.3,13.3,15.8z"
        />
      </svg>
    </SvgIcon>
  )
}
