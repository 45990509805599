import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeContentBox(props) {
  const icon = props.icon
  const title = props.title
  const link = props.link
  const image = props.src
  const target = props.target
  return (
    <div className="homeContentPart">
      {title !== undefined && (
        <h2 className="heading02">
          {icon}
          {title}
        </h2>
      )}
      <div className="imageArea">
        {link === undefined && <img src={image} alt={title} />}
        {link !== undefined && target !== undefined && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={image} alt={title} />
          </a>
        )}
        {link !== undefined && target === undefined && (
          <Link to={link}>
            <img src={image} alt={title} />
          </Link>
        )}
      </div>
    </div>
  )
}
