import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function JhtIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <g id="layer101" stroke="none">
          <path
            d="M22.4,7.8v-1H1.6v1h4.2v6.5c0,1.5-0.2,2.1-1.6,2.1c-0.4,0-1.3-0.1-1.5-0.9c-0.1-0.3-0.1-0.5-0.1-1.8h-1c0,1.3,0,2.2,0.4,2.8
    c0.6,0.8,1.7,0.9,2.1,0.9c2.2,0,2.6-1.4,2.6-2.9V7.8h2.1v9.4h0.9v-4.4h4.5v4.4h0.9V7.8h2.1v6.6c0,1.6,0.3,2.9,2.6,2.9
    c0.4,0,1.6-0.1,2.1-0.9c0.4-0.6,0.4-1.5,0.4-2.8h-1c0,1.3,0,1.4-0.1,1.8c-0.2,0.8-1.1,0.9-1.5,0.9c-1.4,0-1.6-0.6-1.6-2.1V7.8H22.4z
    M14.3,11.8H9.7V7.8h4.5V11.8z"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
