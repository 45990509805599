import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import ScheduleList from './ScheduleList'
import Single from '../components/Single'
import GLOBAL from '../Global'
import '../css/ScheduleTour.css'

const END_POINT = 'https://mk-official-data.firebaseio.com/tour.json'
// const END_POINT = '/data/tour.json'

class Schedule extends Component {
  constructor(props) {
    super(props)
    this.path = props.history.location.pathname // 現在のパス
    this.state = {
      tour: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      const data = result.data
      const latest = data.filter(
        e => e && e.latestSchedule && e.latestSchedule === 'show'
      )
      this.setState({ tour: latest })
    })
  }

  render() {
    const {
      state: { tour },
      props: { history },
    } = this
    const path = history.location.pathname // 現在のパス
    if (path === '/schedule/' || path === '/schedule') {
      return (
        <>
          <h1>{GLOBAL.SCHEDULE.TITLE}</h1>
          <ScheduleList data={tour} />
        </>
      )
    }
    return <Single category="schedule" data={tour} path={path} />
  }
}

export default withRouter(Schedule);

Schedule.propTypes = {
  history: PropTypes.object,
}

Schedule.defaultProps = {
  history: null,
}
