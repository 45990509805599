import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function MkIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <g id="layer101" stroke="none">
          <path 
            d="M0,16.9c0-0.1,0-0.2,0-0.2c0-3.1,0-6.2,0-9.4c0-0.3,0-0.3,0.3-0.3c2.1,0,4.1,0,6.2,0c0.2,0,0.3,0,0.3,0.2
    C7,8.2,7.3,9.2,7.6,10.1c0.1,0.4,0.3,0.9,0.4,1.3c0,0,0,0,0.1,0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.6,0.3-1.2,0.5-1.8C8.7,9,8.8,8.4,9,7.9
    C9,7.7,9.1,7.5,9.1,7.3C9.2,7.1,9.2,7,9.4,7c2.3,0,4.6,0,6.9,0c0.2,0,0.2,0,0.2,0.2c0,1.1,0,2.1,0,3.2c0,0.1,0,0.2,0.1,0.3
    c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.9,1.1-1.8,1.6-2.6C18.8,7.1,18.9,7,19.1,7c1.4,0,2.9,0,4.3,0c0.1,0,0.2,0,0.3,0
    c-0.2,0.3-0.3,0.5-0.5,0.8c-0.8,1.2-1.6,2.4-2.3,3.5c-0.1,0.1-0.1,0.2,0,0.3c1,1.7,2,3.3,3,5c0,0.1,0.1,0.1,0.1,0.2
    c-0.1,0-0.2,0-0.2,0c-1.6,0-3.3,0-4.9,0c-0.1,0-0.2-0.1-0.3-0.2c-0.6-1.2-1.2-2.4-1.8-3.6c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0
    c0,0.1,0,0.2,0,0.2c0,1.2,0,2.3,0,3.5c0,0.3,0,0.3-0.3,0.3c-1.6,0-3.1,0-4.7,0c-0.2,0-0.3-0.1-0.3-0.3c0-1.9,0-3.8,0-5.7
    c0-0.1,0-0.2,0-0.2c0,0,0,0-0.1,0c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.7-0.4,1.5-0.6,2.2c-0.2,0.7-0.4,1.5-0.6,2.2
    c-0.1,0.2-0.1,0.5-0.2,0.7c0,0-0.1,0.1-0.2,0.1c-1,0-2,0-3,0c-0.1,0-0.1-0.1-0.2-0.1C6.2,16.2,6,15.6,5.8,15
    c-0.2-0.8-0.5-1.6-0.7-2.4c-0.2-0.6-0.3-1.1-0.5-1.7c0,0,0-0.1-0.1-0.1c0,0.1,0,0.2,0,0.3c0,1.8,0,3.7,0,5.5c0,0.3,0,0.3-0.3,0.3
    c-1.3,0-2.6,0-3.9,0C0.2,16.9,0.1,16.9,0,16.9z"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
