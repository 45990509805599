import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import RoomIcon from '@material-ui/icons/Room'
import CancelIcon from '@material-ui/icons/Cancel'
import PropTypes from 'prop-types'

const popUps = document.getElementsByClassName('markerPopUp')

class Marker extends Component {
  open = e => {
    const popUp = e.currentTarget.nextSibling
    popUp.style.display = 'block'
    Array.from(popUps).forEach(el => {
      if (el !== popUp) el.style.display = 'none'
    })
  }

  close = e => {
    const popUp = e.currentTarget.parentNode
    popUp.style.display = 'none'
  }

  render() {
    const {
      open,
      close,
      props: { title, content },
    } = this
    return (
      <div className="markerArea">
        <RoomIcon className="marker" onClick={open} />
        <Paper className="markerPopUp">
          <CancelIcon className="markerPopupClose" onClick={close} />
          <div>{title}</div>
          <div className="markerPopUpContent">
            <img
              src={`/images/flags/${content
                .toLowerCase()
                .replace(/\s+/g, '')}.png`}
              alt=""
            />
            {content}
          </div>
        </Paper>
      </div>
    )
  }
}

export default Marker

Marker.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}