import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function MkxIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <polygon points="11.8,5.2 3.5,18.4 11.8,14 "/>
        <polygon points="12.4,5.2 20.7,18.4 12.4,14 "/>
        <polygon points="12,14.4 3.5,18.8 20.7,18.9 "/>
      </svg>
    </SvgIcon>
  )
}
