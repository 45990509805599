import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function MkxIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <g>
        <path d="M2.9,15.7c-0.1,0-0.1,0-0.1-0.1v-0.1c0-0.1,0-0.1,0.1-0.1l1.3-0.1c0.5,0,0.9-0.2,1.2-1.7c0.2-0.9,0.1-1.3-0.1-1.6
              c-0.1-0.2-0.2-0.5-0.2-1c0-0.6,0.5-1.2,1.4-1.6C7,9.2,7.4,9.1,7.6,9c0-0.1-0.1-0.3-0.1-0.4C7.5,7.7,7.5,6.4,7.8,5.2
              c0.4-1.6,1.3-2.4,2.6-2.6c0.9-0.1,1.6,0.1,2.3,0.5c1.2,0.7,2,2.2,2.5,4.3v0c0.1,0.3,0.1,0.6,0.1,1.1c0.2-0.1,0.9-0.4,1.6-0.2
              c0.4,0.1,0.7,0.3,1,0.7c0.4,0.7,0.1,1.8-0.2,2.9c-0.3,1.1-0.6,2.2,0,2.4c1,0.4,1.7,0.2,2.1,0c0,0,0-0.1,0-0.1l1.1-0.7
              c0,0,0.1,0,0.1,0l0.1,0.1c0,0,0,0.1,0,0.1l-1.1,0.7c0,0-0.1,0-0.1,0c-0.2,0.1-0.5,0.4-1,0.4c-0.4,0-0.9-0.1-1.4-0.2
              c-1-0.3-0.6-1.6-0.3-2.9c0.3-1,0.5-2.1,0.2-2.6c-0.2-0.3-0.4-0.5-0.7-0.5c-0.6-0.1-1.3,0.3-1.5,0.3c0,1.2-0.1,2.7-0.6,4.4
              c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.4-0.2,0.9-0.2,1.6c0,0.1,0,0.3,0,0.5c0,1.1,0.1,2.7-0.3,3.6c-0.2,0.7-0.6,1.2-1,1.5
              c-0.5,0.4-1.1,0.6-1.9,0.7c-0.1,0-0.3,0-0.4,0c-0.7,0-1.3-0.3-1.8-0.7c-0.2-0.2-0.4-0.5-0.6-0.8c0,0-0.1-0.2-0.1-0.2
              c0-0.2-0.1-0.5-0.1-0.8c0-0.5,0-1.3,0.2-2c0.1-0.3,0.2-0.5,0.3-0.8c0.4-1.1,0.9-2.3,0.9-3.1c0-0.5-0.4-1.1-0.9-1.8
              C8.4,10.6,8,10,7.8,9.4c-0.3,0.1-2.1,0.5-2.2,1.7c0,0.4,0.1,0.5,0.2,0.8c0.2,0.4,0.3,0.8,0.1,1.8c-0.2,0.8-0.4,1.3-0.7,1.6
              c-0.2,0.2-0.5,0.3-0.8,0.3c0,0-0.1,0.1-0.1,0.1L2.9,15.7z"/>
            <path d="M2.8,15.6c0,0.1,0,0.1,0.1,0.1l1.3-0.1c0,0,0.1,0,0.1-0.1c0.3,0,0.6,0,0.8-0.3c0.3-0.3,0.5-0.8,0.7-1.6
              c0.2-1,0.1-1.5-0.1-1.8c-0.1-0.2-0.2-0.4-0.2-0.8c0.1-1.2,2-1.6,2.2-1.7c0.2,0.6,0.6,1.2,1,1.8c0.4,0.7,0.9,1.4,0.9,1.8
              c0,0.8-0.5,2.1-0.9,3.1c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.6-0.3,1.4-0.2,2c0,0.3,0.1,0.6,0.1,0.8c0,0,0.1,0.2,0.1,0.2
              c0.2,0.3,0.4,0.5,0.6,0.8c0.5,0.5,1.1,0.7,1.8,0.7c0.2,0,0.3,0,0.4,0c0.8,0,1.4-0.3,1.9-0.7c0.4-0.4,0.8-0.9,1-1.5
              c0.3-0.9,0.3-2.5,0.3-3.6c0-0.2,0-0.3,0-0.5c0-0.7,0.1-1.2,0.2-1.6c0-0.1,0.1-0.3,0.1-0.4c0.6-1.7,0.7-3.2,0.6-4.4
              c0.2-0.1,0.9-0.5,1.5-0.3c0.3,0.1,0.5,0.2,0.7,0.5c0.3,0.5,0,1.6-0.2,2.6c-0.3,1.3-0.7,2.6,0.3,2.9c0.5,0.2,1,0.3,1.4,0.2
              c0.5,0,0.8-0.2,1-0.4c0,0,0,0,0.1,0l1.1-0.7c0,0,0.1-0.1,0-0.1l-0.1-0.1c0,0-0.1-0.1-0.1,0l-1.1,0.7c0,0,0,0.1,0,0.1
              c-0.4,0.2-1.1,0.3-2.1,0c-0.6-0.2-0.3-1.3,0-2.4c0.3-1.1,0.6-2.2,0.2-2.9c-0.2-0.4-0.6-0.6-1-0.7c-0.7-0.1-1.4,0.1-1.6,0.2
              c0-0.5-0.1-0.8-0.1-1.1v0c-0.5-2.1-1.3-3.5-2.5-4.3c-0.7-0.4-1.4-0.6-2.3-0.5c-1.3,0.1-2.2,1-2.6,2.6C7.5,6.4,7.5,7.7,7.6,8.6
              c0,0.1,0,0.3,0.1,0.4C7.4,9.1,7,9.2,6.5,9.4c-0.8,0.4-1.3,1-1.4,1.6c0,0.5,0.1,0.7,0.2,1c0.1,0.3,0.3,0.7,0.1,1.6
              c-0.4,1.5-0.8,1.7-1.2,1.7l-1.3,0.1c0,0-0.1,0.1-0.1,0.1V15.6z M2.6,15.6v-0.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1
              l1.3-0.1c0.3,0,0.6-0.1,1-1.5c0.2-0.8,0.1-1.1-0.1-1.4C5,11.9,4.8,11.5,4.9,11c0.1-0.8,0.6-1.4,1.5-1.8c0.3-0.2,0.7-0.3,1-0.3
              c0-0.1,0-0.2,0-0.2C7.3,7.7,7.2,6.4,7.6,5.1C8,3.5,9,2.5,10.4,2.4c0.9-0.1,1.7,0.1,2.5,0.5c1.2,0.8,2.1,2.2,2.6,4.4
              c0,0.2,0.1,0.5,0.1,0.8C15.9,8,16.4,7.9,17,8c0.5,0.1,0.8,0.4,1.1,0.8c0.5,0.8,0.2,1.9-0.1,3.1c-0.2,1-0.5,2-0.1,2.2
              c0.9,0.3,1.5,0.2,1.9,0.1c0,0,0.1-0.1,0.1-0.1l1.1-0.7c0.2-0.1,0.4-0.1,0.5,0.1l0.1,0.1v0c0.1,0.2,0,0.4-0.1,0.5l-1.1,0.7
              c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.6,0.4-1.1,0.4c-0.5,0-1-0.1-1.5-0.2c-1.2-0.4-0.8-1.9-0.4-3.2c0.2-0.9,0.5-1.9,0.2-2.4
              c-0.1-0.2-0.3-0.4-0.5-0.4c-0.4-0.1-0.9,0.1-1.2,0.2c0,1.5-0.2,2.9-0.6,4.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.4-0.2,0.8-0.2,1.5v0.4
              c0,1.1,0.1,2.7-0.3,3.7c-0.3,0.7-0.6,1.2-1.1,1.6c-0.5,0.4-1.2,0.7-2.1,0.7c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
              c-0.8,0-1.4-0.3-2-0.8c-0.2-0.2-0.5-0.5-0.6-0.8C8.1,20,8,19.9,8,19.8c0-0.2-0.1-0.5-0.1-0.8c0-0.6,0-1.4,0.3-2.1
              c0.1-0.3,0.2-0.5,0.3-0.8c0.4-1.1,0.9-2.2,0.9-3c0-0.4-0.4-1.1-0.9-1.7c-0.3-0.5-0.7-1.1-0.9-1.6c-0.5,0.1-1.8,0.6-1.9,1.4
              c0,0.3,0.1,0.4,0.1,0.6c0.2,0.4,0.4,0.9,0.1,2c-0.2,0.9-0.4,1.4-0.8,1.7c-0.3,0.3-0.6,0.3-0.8,0.3c-0.1,0.1-0.1,0.1-0.2,0.1
              L2.9,16C2.7,16,2.6,15.8,2.6,15.6z"/>
        </g>
      </svg>
    </SvgIcon>
  )
}
