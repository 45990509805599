import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function HmkIcon() {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <g id="layer101" stroke="none">
        <rect x="12.6" y="14.7" width="3.5" height="1.5"/>
	<rect x="12.6" y="3.5" width="3.5" height="3.5"/>
	<rect x="12.6" y="8.1" width="3.5" height="1.5"/>
	<rect x="8.1" y="8.1" width="3.5" height="1.5"/>
	<path d="M16.1,2.2c-1.1-0.4-2.3-0.7-3.5-0.8v1.1h3.5V2.2z"/>
	<path d="M11.6,1.4c-1.2,0.1-2.4,0.3-3.5,0.8v0.3h3.5V1.4z"/>
	<path d="M18.5,3.5h-1.4V7h3.5V5.7C20,4.9,19.3,4.1,18.5,3.5z"/>
	<path d="M22,8.1h-0.3v3.5h1.1C22.7,10.3,22.4,9.2,22,8.1z"/>
	<rect x="17.1" y="8.1" width="3.5" height="1.5"/>
	<rect x="17.1" y="14.7" width="3.5" height="1.5"/>
	<rect x="12.6" y="17.1" width="3.5" height="3.5"/>
	<rect x="8.1" y="3.5" width="3.5" height="3.5"/>
	<path d="M2.2,16.1h0.3v-3.5H1.4C1.5,13.8,1.7,15,2.2,16.1z"/>
	<path d="M8.1,22c1.1,0.4,2.3,0.7,3.5,0.8v-1.1H8.1V22z"/>
	<path d="M12.6,22.8c1.2-0.1,2.4-0.3,3.5-0.8v-0.3h-3.5V22.8z"/>
	<path d="M21.7,16.1H22c0.4-1.1,0.7-2.3,0.8-3.5h-1.1V16.1z"/>
	<path d="M3.5,18.5c0.6,0.8,1.3,1.5,2.1,2.1H7v-3.5H3.5V18.5z"/>
	<path d="M17.1,20.6h1.4c0.8-0.6,1.5-1.3,2.1-2.1v-1.4h-3.5V20.6z"/>
	<path d="M7,3.5H5.7C4.9,4.1,4.1,4.9,3.5,5.7V7H7V3.5z"/>
	<rect x="8.1" y="17.1" width="3.5" height="3.5"/>
	<rect x="8.1" y="14.7" width="3.5" height="1.5"/>
	<rect x="3.5" y="8.1" width="3.5" height="1.5"/>
	<rect x="3.5" y="14.7" width="3.5" height="1.5"/>
	<path d="M1.4,11.6h1.1V8.1H2.2C1.7,9.2,1.5,10.3,1.4,11.6z"/>
	<path d="M5.5,10.1H4.7v4.2h0.5v-2.5c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5v-0.1l1.2,3.6H7l1.2-3.6c0,0.2,0,0.4,0,0.6
		c0,0.2,0,0.3,0,0.4v2.5h0.5v-4.2H7.9l-1.2,3.6L5.5,10.1z"/>
	<rect x="11.3" y="10.1" width="0.6" height="4.2"/>
	<polygon points="18.4,14.3 19.5,10.1 18.9,10.1 18.1,13.5 17.1,10.1 16.5,10.1 15.6,13.5 14.8,10.1 14.1,10.1 15.2,14.3 15.8,14.3 
		16.8,10.8 17.8,14.3 	"/>
        </g>
      </svg>
    </SvgIcon>
  )
}
