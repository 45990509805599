export default {
  SITENAME: 'MK OFFICIAL WEB PAGE',
  HOME: {
    MAINTEXT: 'A PERMANENT TRAVELER',
    TOPICSTITLE: "WHAT'S NEW",
    CONTENT_TITLE1: 'COMING UP',
    CONTENT_TITLE2: 'PHOTO',
    CONTENT_TITLE3: 'LINK',
    CONTENT_TITLE4: 'SPECIAL',
    CONTENT_TITLE5: 'MAP',
  },
  ABOUT: {
    TITLE: 'ABOUT',
    TEXT: '<p>MICHIHIRO KOSEKI : A PERMANENT TRAVELER</p>',
  },
  TOPICS: {
    TITLE: 'TOPICS',
  },
  SCHEDULE: {
    TITLE: 'SCHEDULE',
  },
  TOUR: {
    TITLE: 'TOUR',
    INFORMATION: 'INFORMATION',
    COOPERATIONS: 'COOPERATIONS',
    NOTE: 'NOTE',
    ROUTEMAP: 'ROUTE MAP',
    SCHEDULE_TITLE: 'SCHEDULE',
    SCHEDULE: {
      DATE: 'DATE',
      CITY: 'CITY',
      COUNTRY: 'COUNTRY',
    },
    FROM_HUMANKIND_TITLE: 'FROM HUMANKIND',
  },
  COPYRIGHT: '&copy;MID KNITE JAPAN INTERNATIONAL LTD. ALL RIGHTS RESERVED.',
}
